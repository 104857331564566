import { format as dfFormat } from 'date-fns';

/**
 * @name isToday
 *
 * @desc Checks whether the given date string is today
 * @param {String} dateString the date string which based on ISO 8601 (e.g. "2019-09-12T11:26:41+00:00")
 *
 * @return {Boolean}
 */
export function isToday(dateString: string): boolean {
  if (!dateString) {
    return false;
  }

  const date = new Date(dateString);
  const nowDate = new Date();
  return date.getDate() === nowDate.getDate();
}

/**
 * @name formatDate
 *
 * @desc Formats date to formated date string with Unicode Tokens.
 * @param {Date} date the date that will be formatted
 * @param {String} formattingRule the rule to format date string (See: https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table)
 *
 * @return {String} returns the formated date string
 */
export function formatDate(date: Date, formattingRule: string): string {
  return dfFormat(date, formattingRule);
}

/**
 * @name formatDateString
 *
 * @desc Formats date string to formated date string with Unicode Tokens.
 * @param {String} dateString the date string which based on ISO 8601 (e.g. "2019-09-12T11:26:41+00:00")
 * @param {String} formattingRule the rule to format date string (See: https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table)
 *
 * @return {String} returns the formated date string
 */
export function formatDateString(dateString: string, formattingRule: string): string {
  const date = new Date(dateString);
  return formatDate(date, formattingRule);
}
