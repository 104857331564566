import { Component, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash';

@Component
export default class Marquee extends Vue {
  isLongContents = false;

  mounted(): void {
    // Wait for loading child component
    setTimeout(() => this.checkLongContents(), 0);
    window.addEventListener('resize', debounce(() => this.checkLongContents(), 100));
  }

  checkLongContents(): void {
    const contentsElm = this.$el.lastElementChild;
    const marqueeRef = this.$refs.marqueeItem;
    if (!contentsElm || !marqueeRef) {
      return;
    }

    const clientWidth = Math.round(contentsElm.getBoundingClientRect().width);
    const marqueeWidth = (marqueeRef as Element).clientWidth;
    this.isLongContents = clientWidth - marqueeWidth < 0;
  }
}
